import React from "react";
import { filters } from "../utils/txnType";
import { currencies } from "../utils/currencies";
import CoinIcon from "../assets/coin.svg";
import RightArrow from "../assets/Icons/Arrow-right.svg";
import ExplorerIcon from "../assets/Icons/Explorer.svg";
import useStore from "../store";

export const TxnTable = ({ txns }) => {
  const { activeCurrency, conversionRate, chains } = useStore();
  const getToken = (txn) => {
    switch (txn.type) {
      case "send":
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row relative items-end">
              <img
                src={`https://storage.googleapis.com/safle-asset-icons/${txn?.sent?.symbol}.png`}
                alt={filters?.find((filter) => filter.key === txn?.type)?.name}
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                  e.target.src = CoinIcon;
                }}
                className="w-8 h-8 mr-2 rounded-full border-2 border-transparent"
              />
              <img
                src={
                  chains?.find(
                    (chain) => chain?.name?.toLowerCase() === txn?.chain
                  )?.logo_url
                }
                alt="Chain logo"
                loading="lazy"
                className="rounded-full w-[15px] h-[15px] relative bottom-[-5px] right-[1rem] border-2 border-[#6D7072] bg-white"
              />
            </div>
            <div>
              <div>{txn?.sent?.name || txn?.sent?.symbol}</div>
              <div className="text-[#6D7072] text-xs">
                {txn?.chain.charAt(0).toUpperCase() +
                  txn?.chain.slice(1).toLowerCase()}
              </div>
            </div>
          </div>
        );
      case "receive":
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row relative items-end">
              <img
                src={`https://storage.googleapis.com/safle-asset-icons/${txn?.received?.symbol}.png`}
                alt={filters?.find((filter) => filter.key === txn?.type)?.name}
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                  e.target.src = CoinIcon;
                }}
                className="w-8 h-8 mr-2"
              />
              <img
                src={
                  chains?.find(
                    (chain) => chain?.name?.toLowerCase() === txn?.chain
                  )?.logo_url
                }
                alt="Chain logo"
                loading="lazy"
                className="rounded-full w-[15px] h-[15px] relative bottom-[-5px] right-[1rem] border-2 border-[#6D7072] bg-white"
              />
            </div>
            <div>
              <div>{txn?.received?.name || txn?.received?.symbol}</div>
              <div className="text-[#6D7072] text-xs">
                {txn?.chain.charAt(0).toUpperCase() +
                  txn?.chain.slice(1).toLowerCase()}
              </div>
            </div>
          </div>
        );
      case "swap":
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              <div className="flex flex-row relative items-end">
                <img
                  src={`https://storage.googleapis.com/safle-asset-icons/${txn?.sent?.symbol}.png`}
                  alt={
                    filters?.find((filter) => filter.key === txn?.type)?.name
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                    e.target.src = CoinIcon;
                  }}
                  className="w-8 h-8 mr-2"
                />
                <img
                  src={
                    chains?.find(
                      (chain) => chain?.name?.toLowerCase() === txn?.chain
                    )?.logo_url
                  }
                  alt="Chain logo"
                  loading="lazy"
                  className="rounded-full w-[15px] h-[15px] relative bottom-[-5px] right-[1rem] border-2 border-[#6D7072] bg-white"
                />
              </div>
              <div>
                <div>
                  {txn?.sent?.name || txn?.sent?.symbol > 15
                    ? txn?.sent?.symbol?.slice(0, 15) + "..."
                    : txn?.sent?.symbol}
                </div>
                <div className="text-[#6D7072] text-xs">{txn?.chain}</div>
              </div>
            </div>
            <img src={RightArrow} alt={"swapped to"} />
            <div className="flex flex-row items-center">
              <div className="flex flex-row relative items-end">
                <img
                  src={`https://storage.googleapis.com/safle-asset-icons/${txn?.received?.symbol}.png`}
                  alt={
                    filters?.find((filter) => filter.key === txn?.type)?.name
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                    e.target.src = CoinIcon;
                  }}
                  className="w-8 h-8 mr-2"
                />
                <img
                  src={
                    chains?.find(
                      (chain) => chain?.name?.toLowerCase() === txn?.chain
                    )?.logo_url
                  }
                  alt="Chain logo"
                  loading="lazy"
                  className="rounded-full w-[15px] h-[15px] relative bottom-0 right-[1rem] border-2 border-[#6D7072] bg-white"
                />
              </div>
              <div>
                <div>
                  {txn?.received?.name || txn?.received?.symbol > 15
                    ? txn?.received?.symbol?.slice(0, 15) + "..."
                    : txn?.received?.symbol}
                </div>
                <div className="text-[#6D7072] text-xs">
                  {txn?.chain.charAt(0).toUpperCase() +
                    txn?.chain.slice(1).toLowerCase()}
                </div>
              </div>
            </div>
          </div>
        );
      case "approve":
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row relative items-end">
              <img
                src={`https://storage.googleapis.com/safle-asset-icons/${txn?.sent?.symbol}.png`}
                alt={filters?.find((filter) => filter.key === txn?.type)?.name}
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                  e.target.src = CoinIcon;
                }}
                className="w-8 h-8 mr-2"
              />
              <img
                src={
                  chains?.find(
                    (chain) => chain?.name?.toLowerCase() === txn?.chain
                  )?.logo_url
                }
                alt="Chain logo"
                loading="lazy"
                className="rounded-full w-[15px] h-[15px] relative bottom-[-5px] right-[1rem] border-2 border-[#6D7072] bg-white"
              />
            </div>
            <div>
              <div>{txn?.sent?.name || txn?.sent?.symbol}</div>
              <div className="text-[#6D7072] text-xs">
                {txn?.chain.charAt(0).toUpperCase() +
                  txn?.chain.slice(1).toLowerCase()}
              </div>
            </div>
          </div>
        );
      case "contract_execution":
        return (
          <div className="flex flex-row items-center">
            {txn?.sent && (
              <div className="flex flex-row items-center">
                <img
                  src={`https://storage.googleapis.com/safle-asset-icons/${txn?.sent?.symbol}.png`}
                  alt={
                    filters?.find((filter) => filter.key === txn?.type)?.name
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                    e.target.src = CoinIcon;
                  }}
                  className="w-8 h-8 mr-2"
                />
                <div>
                  <div>{txn?.sent?.name || txn?.sent?.symbol}</div>
                  <div className="text-[#6D7072] text-xs">{txn?.chain}</div>
                </div>
              </div>
            )}
            {txn?.received && (
              <img src={RightArrow} loading="lazy" alt={"swapped to"} />
            )}
            {txn?.received && (
              <div className="flex flex-row items-center">
                <img
                  src={`https://storage.googleapis.com/safle-asset-icons/${txn?.received?.symbol}.png`}
                  alt={
                    filters?.find((filter) => filter.key === txn?.type)?.name
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                    e.target.src = CoinIcon;
                  }}
                  className="w-8 h-8 mr-2"
                />
                <div>
                  <div>{txn?.received?.name || txn?.received?.symbol}</div>
                  <div className="text-[#6D7072] text-xs">
                    {txn?.chain.charAt(0).toUpperCase() +
                      txn?.chain.slice(1).toLowerCase()}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return (
          <div className="flex flex-row items-center">
            {txn?.sent && (
              <div className="flex flex-row items-center">
                <img
                  src={`https://storage.googleapis.com/safle-asset-icons/${txn?.sent?.symbol}.png`}
                  alt={
                    filters?.find((filter) => filter.key === txn?.type)?.name
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                    e.target.src = CoinIcon;
                  }}
                  className="w-8 h-8 mr-2"
                />
                <img
                  src={
                    chains?.find(
                      (chain) => chain?.name?.toLowerCase() === txn?.chain
                    )?.logo_url
                  }
                  alt="Chain logo"
                  loading="lazy"
                  className="rounded-full w-[15px] h-[15px] relative bottom-0 right-[10px] border-2 border-[#6D7072] bg-white"
                />
                <div>
                  <div>
                    {txn?.sent?.name || txn?.sent?.symbol > 15
                      ? txn?.sent?.symbol?.slice(0, 15) + "..."
                      : txn?.sent?.symbol}
                  </div>
                  <div className="text-[#6D7072] text-xs">{txn?.chain}</div>
                </div>
              </div>
            )}
            {txn?.received && (
              <img src={RightArrow} loading="lazy" alt={"swapped to"} />
            )}
            {txn?.received && (
              <div className="flex flex-row items-center">
                <img
                  src={`https://storage.googleapis.com/safle-asset-icons/${txn?.received?.symbol}.png`}
                  alt={
                    filters?.find((filter) => filter.key === txn?.type)?.name
                  }
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                    e.target.src = CoinIcon;
                  }}
                  loading="lazy"
                  className="w-8 h-8 mr-2"
                />
                <img
                  src={
                    chains?.find(
                      (chain) => chain?.name?.toLowerCase() === txn?.chainName
                    )?.logo_url
                  }
                  alt="Chain logo"
                  loading="lazy"
                  className="rounded-full w-[15px] h-[15px] relative bottom-0 right-[10px] border-2 border-[#6D7072] bg-white"
                />
                <div>
                  <div>
                    {txn?.received?.name || txn?.received?.symbol > 15
                      ? txn?.received?.symbol?.slice(0, 15) + "..."
                      : txn?.received?.symbol}
                  </div>
                  <div className="text-[#6D7072] text-xs">
                    {txn?.chain.charAt(0).toUpperCase() +
                      txn?.chain.slice(1).toLowerCase()}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
    }
  };

  const getAmount = (txn) => {
    switch (txn.type) {
      case "send":
        return txn?.sent?.value;
      case "receive":
        return txn?.received?.value;
      case "swap":
        return txn?.sent?.value;
      case "approve":
        return txn?.sent?.value;
      case "contract_execution":
        return txn?.sent?.value;
      default:
        return 0;
    }
  };

  // Function to format the amount
  function formatAmount(amount) {
    if (amount !== undefined && amount !== null) {
      return parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "0.00"; // Or some default value
  }

  // Function to truncate the string and add ellipsis in the middle
  function truncateMiddle(str, frontChars = 5, backChars = 5) {
    if (!str) return "";
    if (str.length <= frontChars + backChars) return str;

    const front = str?.slice(0, frontChars);
    const back = str?.slice(-backChars);
    return `${front}...${back}`;
  }

  const getExplorerLink = (chain, transactionId) => {
    const chainInfo = chains.find(
      (c) => c.name.toLowerCase() === chain.toLowerCase()
    );
    return chainInfo
      ? `${chainInfo.block_expolrer_url}/tx/${transactionId}`
      : "#";
  };

  return (
    <div className="w-full mx-auto p-4">
      {/* <h1 className="text-2xl font-bold mb-4">Crypto Portfolio</h1> */}
      <div className="hidden md:block">
        <table className="min-w-full">
          <thead class="sticky top-[4.5rem]" style={{ zIndex: 10 }}>
            <tr className="bg-slate-200 dark:bg-[#141719] dark:text-[#6D7072] text-base text-left">
              <th className="py-2 px-4 font-normal w-[10%]">Txn</th>
              <th className="py-2 px-4 font-normal w-[30%]">Token</th>
              <th className="py-2 px-4 font-normal w-[10%]">From</th>
              <th className="py-2 px-4 font-normal w-[10%]">To</th>
              <th className="py-2 px-4 font-normal w-[10%]">Amount</th>
              <th className="py-2 px-4 font-normal w-[10%]">Fees</th>
              <th className="py-2 px-4 font-normal w-[10%]">Current Value</th>
              <th className="py-2 px-4 font-normal w-[10%]"></th>
            </tr>
          </thead>
          <tbody className="bg-slate-100 dark:bg-[#1A1D20]">
            {txns?.map((txn, index) => (
              <tr key={index} className="text-left text-sm">
                <td className="py-4 px-4">
                  <div className="flex">
                    <img
                      src={
                        filters?.find((filter) => filter.key === txn?.type)
                          ?.icon
                      }
                      alt={
                        filters?.find((filter) => filter.key === txn?.type)
                          ?.name
                      }
                      className="w-8 h-8 mr-4 rounded-full bg-[#25292D]"
                    />
                    {filters?.find((filter) => filter.key === txn?.type)?.name}
                  </div>
                </td>
                <td className="flex justify-between py-4 px-4">
                  <div className="flex">{getToken(txn)}</div>
                </td>
                <td className="py-4 px-4">
                  {txn?.type === "received"
                    ? truncateMiddle(txn?.from)
                    : truncateMiddle(txn?.to)}
                </td>
                <td className="py-4 px-4">
                  {txn?.type === "received"
                    ? truncateMiddle(txn?.to)
                    : truncateMiddle(txn?.from)}
                </td>
                <td className="py-4 px-4">
                  {formatAmount(getAmount(txn))}{" "}
                  {txn?.sent?.symbol || txn?.received?.symbol > 15
                    ? txn?.sent?.symbol?.slice(0, 15) + "..."
                    : txn?.sent?.symbol}
                </td>
                <td className="py-4 px-4">
                  {txn?.fee % 1 === 0
                    ? (txn?.fee * conversionRate).toString()
                    : (txn?.fee * conversionRate).toFixed(
                        Math.min(6, txn?.fee.toString().split(".")[1].length)
                      )}{" "}
                  {txn?.sent?.symbol || txn?.received?.symbol > 15
                    ? txn?.sent?.symbol?.slice(0, 15) + "..."
                    : txn?.sent?.symbol}
                </td>
                <td className="py-4 px-4">
                  {
                    currencies?.find(
                      (currency) =>
                        currency["Currency Code"].toLowerCase() ===
                        activeCurrency
                    )?.["Currency Symbol"]
                  }
                  {formatAmount(getAmount(txn) * conversionRate)}
                </td>
                <td className="py-4 px-4">
                  <img
                    src={ExplorerIcon}
                    alt="Spam Icon"
                    className="w-4 h-4 invert dark:invert-0"
                    onClick={() =>
                      window.open(getExplorerLink(txn.chain, txn.id), "_blank")
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Txn table for mobile view */}

      <div className="min-w-full block md:hidden">
        {txns?.map((txn, index) => (
          <div
            key={index}
            className="w-full bg-[#1A1D20] p-4 mb-4 border-[1px] border-[#6D7072] rounded-lg grid grid-cols-1 divide-y"
          >
            <div className="w-full flex justify-between items-center pb-2 mb-2">
              <div className="flex items-center">
                <span className="text-white">{getToken(txn)}</span>
              </div>
              <div className="flex">
                <span className="block text-[#6D7072]">
                  {filters?.find((filter) => filter.key === txn?.type)?.name}
                </span>
                <img
                  src={
                    filters?.find((filter) => filter.key === txn?.type)?.icon
                  }
                  alt={
                    filters?.find((filter) => filter.key === txn?.type)?.name
                  }
                  className="w-8 h-8 mr-2"
                />
              </div>
            </div>

            <div className="w-full flex justify-between items-center pb-2 mb-2 pt-2">
              <div className="flex flex-col">
                <span className="text-[#6D7072]">From</span>
                <span className="text-white w-20 truncate">
                  {" "}
                  {/* Reduced width */}
                  {txn?.type === "received"
                    ? txn?.from?.slice(0, 6)
                    : txn?.to?.slice(0, 6)}{" "}
                  {/* Show only the first 6 characters */}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="block text-[#6D7072]">To</span>
                <span className="text-white w-20 truncate">
                  {" "}
                  {/* Reduced width */}
                  {txn?.type === "received"
                    ? txn?.to?.slice(0, 6)
                    : txn?.from?.slice(0, 6)}{" "}
                  {/* Show only the first 6 characters */}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="block text-[#6D7072]">Fee</span>
                <span className="text-white">
                  {txn?.fee % 1 === 0
                    ? (txn?.fee * conversionRate).toString()
                    : (txn?.fee * conversionRate).toFixed(
                        Math.min(6, txn?.fee.toString().split(".")[1].length)
                      )}{" "}
                  {txn?.sent?.symbol || txn?.received?.symbol}
                </span>
              </div>
            </div>

            <div className="w-full flex justify-between items-center pb-2 mb-2 p-2">
              <div className="flex flex-col">
                <span className="text-[#6D7072]">Amount</span>
                <span className="text-white">
                  {getAmount(txn)} {txn?.sent?.symbol || txn?.received?.symbol}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="block text-[#6D7072]">Current Value</span>
                <span className="text-white">
                  {
                    currencies?.find(
                      (currency) =>
                        currency["Currency Code"].toLowerCase() ===
                        activeCurrency
                    )?.["Currency Symbol"]
                  }
                  {(getAmount(txn) * conversionRate)?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
