import React from "react";
import { DownloadAppButton } from "./DownloadAppPopUp";
import ThemeIcon from "../assets/Icons/theme.svg";
import useStore from "../store";
import safleLogo from "../assets/SafleLens.svg";
import SafleLogoLightMode from "../assets/Icons/safle-logo-lightMode.svg";

export const NavbarHome = () => {

  const { theme, setTheme } = useStore();

  return (
    <div className="flex justify-evenly p-4 bg-white dark:bg-[#141719] dark:text-white text-black w-full">
      <div className="pr-[44rem]">
      {theme === "light" ? (
              <img
                src={SafleLogoLightMode}
                alt="Safle Logo"
                className="w-[9rem] h-[3rem] mr-2"
              />
            ) : (
              <img
                src={safleLogo}
                alt="Safle Logo"
                className="w-[9rem] h-[3rem] mr-2"
              />
            )}
      </div>
      <div className="flex items-center ">
        <div className="pr-4">
          <DownloadAppButton />
        </div>
        <img
          onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
          src={ThemeIcon}
          alt="Theme Icon"
          className="w-10 h-10 cursor-pointer" // Adjust size as needed
        />
      </div>
    </div>
  );
};
