// src/components/ChainDropdown.js
import React, { useState, useEffect, useRef } from "react";
import useStore from "../store";
import { currencies } from "../utils/currencies";
import SearchIcon from "../assets/Icons/Search.svg";
import CheckIcon from "../assets/Icons/Check.svg";
import ToggleIcon from "../assets/Icons/Arrow - Down.svg";

export const CurrencyDropDown = () => {
  const [search, setSearch] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { activeCurrency, setActiveCurrency } = useStore();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if(!dropdownOpen) {
      setSearch("")
    }
  }, [dropdownOpen])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleChain = (currencySymbol) => {
    setActiveCurrency(currencySymbol);
  };

  const filteredCurrency = currencies
    .filter(
      (currency) =>
        currency?.["Currency Name"]
          ?.toLowerCase()
          ?.includes(search.toLowerCase()) ||
        currency?.["Currency Code"]
          ?.toLowerCase()
          ?.includes(search.toLowerCase())
    )
    .sort(
      (a, b) => (b.popular === "true" ? 1 : 0) - (a.popular === "true" ? 1 : 0)
    );

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="px-2 py-1 bg-white dark:bg-[#1A1D20] text-[13px] text-[#6D7072] border-[1px] border-[#25292D] rounded-2xl flex"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div>{activeCurrency?.toUpperCase()}</div>
        <div className="flex pl-1 pt-[0.2rem]">
          <img
            src={ToggleIcon}
            alt="Check Icon"
            className="invert-0 dark:invert h-[15px] w-[15px]"
          />
        </div>
      </button>

      {dropdownOpen && (
        <div
          className="absolute mt-2 w-56 bg-white dark:bg-[#141719] border border-[#25292D] rounded-lg shadow-lg"
          style={{ zIndex: 1000 }}
        >
          <div className="flex items-center justify-between px-4 py-4 relative ">
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="absolute left-6 top-1/2 transform -translate-y-1/2 w-5 h-5 invert"
              style={{
                filter:
                  "invert(29%) sepia(20%) saturate(277%) hue-rotate(192deg)",
              }}
            />
            <input
              type="text"
              placeholder="Search"
              className="w-full pl-8 px-2 py-1 border border-[#25292D] rounded-2xl text-white bg-transparent dark:text-black dark:border-[#E9EAEA] dark:bg-white"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <button
              className="ml-2 px-2 py-1 bg-green-500 text-white rounded-md"
              onClick={selectAllChains}
            >
              Select All
            </button> */}
          </div>
          <div className="max-h-60 overflow-y-auto no-scrollbar mb-4">
            {filteredCurrency.map((currency) => (
              <div
                key={currency?.["Currency Code"]?.toLowerCase()}
                className="flex items-center justify-between px-2 py-2 dark:hover:bg-[#1A1D20] mx-4 rounded-xl cursor-pointer dark:text-[#6D7072] hover:bg-[#F2F2F2]"
                onClick={() =>
                  toggleChain(currency?.["Currency Code"]?.toLowerCase())
                }
              >
                <div className="flex flex-row gap-4 items-center">
                  <div className="flex items-center justify-center rounded-full overflow-hidden w-[24px] h-[24px]">
                    <img
                      src={currency?.["Country Flag"]}
                      alt="currency logo"
                      className="h-[100%]"
                    />
                  </div>
                  <span>
                    {currency?.["Currency Code"]} -{" "}
                    {currency?.["Currency Symbol"]}
                  </span>
                </div>
                <div
                  className={`${
                    activeCurrency?.toLowerCase() ===
                    currency?.["Currency Code"]?.toLowerCase()
                      ? "block"
                      : "hidden"
                  }`}
                >
                  <img
                    src={CheckIcon}
                    alt="Check Icon"
                    className="invert-0 dark:invert dark:filter-none dark:opacity-70"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
