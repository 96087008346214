import React, { useState, useMemo } from "react";
import useStore from "../store";
import { currencies } from "../utils/currencies";
import CoinIcon from "../assets/coin.svg";
import ArrowUpIcon from "../assets/Icons/up-arrow.svg";
import ArrowDownIcon from "../assets/Icons/down-arrow.svg";
import SpamIcon from "../assets/Icons/SPAM-ticker.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import AI from "../assets/Icons/AI.svg";
// import AIICON2 from "../assets/Icons/AI-icon-2.svg";
import AIICON2 from "../assets/Icons/new-ai-icon.svg";

export const AssetTable = ({ assets }) => {
  console.log(assets);
  const {
    conversionRate,
    activeCurrency,
    chains,
    setIsAIActive,
    setChatContext,
    setActiveAIToken,
  } = useStore();
  // State to manage sort direction for each column
  const theme = useStore((state) => state.theme);
  const [sortDirection, setSortDirection] = useState({
    balance: null,
    price: null,
    holdings: null,
    "24hChange": null,
  });

  const handleAIClick = (asset) => {
    setIsAIActive(true);
    setChatContext("token");
    setActiveAIToken(asset);
  };

  // Function to handle sorting
  const handleSort = (column) => {
    setSortDirection((prev) => {
      const direction = prev[column] === "asc" ? "desc" : "asc";
      return {
        ...prev,
        [column]: direction,
      };
    });
  };

  const sortedAssets = useMemo(() => {
    if (!Object.values(sortDirection).some((dir) => dir !== null))
      return assets;

    const activeSortColumn = Object.keys(sortDirection).find(
      (column) => sortDirection[column] !== null
    );
    const direction = sortDirection[activeSortColumn];

    return [...assets].sort((a, b) => {
      let aValue, bValue;

      switch (activeSortColumn) {
        case "balance":
          aValue = a.balance;
          bValue = b.balance;
          break;
        case "price":
          aValue = a.quote * conversionRate;
          bValue = b.quote * conversionRate;
          break;
        case "holdings":
          aValue = a.quote_rate * conversionRate;
          bValue = b.quote_rate * conversionRate;
          break;
        case "24hChange":
          aValue = a.quote_pct_change_24h;
          bValue = b.quote_pct_change_24h;
          break;
        default:
          return 0;
      }

      if (direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [assets, sortDirection, conversionRate]);

  // Calculate portfolio percentages
  const totalPortfolioValue = assets?.reduce(
    (total, asset) => total + asset.quote,
    0
  );
  function formatAssetBalance(balance) {
    const fractionDigits = Math.min(
      6,
      balance.toString().split(".")[1]?.length || 0
    );

    return balance.toLocaleString("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits,
    });
  }

  // eslint-disable-next-line no-unused-vars
  function formatNumber(value, maxFractionDigits = 2) {
    const fractionDigits = Math.min(
      maxFractionDigits,
      value.toString().split(".")[1]?.length || 0
    );

    return value.toLocaleString("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits,
    });
  }

  return (
    <div className="mx-auto px-4 border-separate overflow-clip">
      <div className="table-container relative overflow-hidden">
        <div className="table-header sticky top-0 z-10">
          <table className="w-full table-fixed">
            <thead className="w-full table-fixed" style={{ zIndex: 10 }}>
              <tr className="bg-slate-200 dark:bg-[#141719] text-[#6D7072] text-base text-left">
                <th className="py-2 px-4 font-normal items-center text-ellipsis">
                  Token
                </th>
                <th className="py-2 px-4 font-normal w-1/6 items-center">
                  <div className="flex items-center">
                    Balance
                    <div className="flex flex-col ml-2">
                      <button onClick={() => handleSort("balance")}>
                        <img
                          src={ArrowUpIcon}
                          alt="Sort Ascending"
                          className={`h-2 w-2 ${
                            sortDirection.balance === "asc"
                              ? "text-green-500"
                              : ""
                          }`}
                        />
                      </button>
                      <button onClick={() => handleSort("balance")}>
                        <img
                          src={ArrowDownIcon}
                          alt="Sort Descending"
                          className={`h-2 w-2 ${
                            sortDirection.balance === "desc"
                              ? "text-red-500"
                              : ""
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                </th>
                <th className="py-2 px-4 font-normal w-1/6 items-center">
                  <div className="flex items-center">
                    Price
                    <div className="flex flex-col ml-2">
                      <button onClick={() => handleSort("price")}>
                        <img
                          src={ArrowUpIcon}
                          alt="Sort Ascending"
                          className={`h-2 w-2 ${
                            sortDirection.price === "asc"
                              ? "text-green-500"
                              : ""
                          }`}
                        />
                      </button>
                      <button onClick={() => handleSort("price")}>
                        <img
                          src={ArrowDownIcon}
                          alt="Sort Descending"
                          className={`h-2 w-2 ${
                            sortDirection.price === "desc" ? "text-red-500" : ""
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                </th>
                <th className="py-2 px-4 font-normal w-1/6 items-center">
                  <div className="flex items-center">
                    Holdings in {activeCurrency?.toUpperCase()}
                    <div className="flex flex-col ml-2">
                      <button onClick={() => handleSort("holdings")}>
                        <img
                          src={ArrowUpIcon}
                          alt="Sort Ascending"
                          className={`h-2 w-2 ${
                            sortDirection.holdings === "asc"
                              ? "text-green-500"
                              : ""
                          }`}
                        />
                      </button>
                      <button onClick={() => handleSort("holdings")}>
                        <img
                          src={ArrowDownIcon}
                          alt="Sort Descending"
                          className={`h-2 w-2 ${
                            sortDirection.holdings === "desc"
                              ? "text-red-500"
                              : ""
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                </th>
                <th className="py-2 px-4 font-normal w-1/6 items-center">
                  Portfolio %
                </th>
                <th className="py-2 px-4 font-normal w-1/6 items-center">
                  <div className="flex items-center">
                    24h Change
                    <div className="flex flex-col ml-2">
                      <button onClick={() => handleSort("24hChange")}>
                        <img
                          src={ArrowUpIcon}
                          alt="Sort Ascending"
                          className={`h-2 w-2 ${
                            sortDirection["24hChange"] === "asc"
                              ? "text-green-500"
                              : ""
                          }`}
                        />
                      </button>
                      <button onClick={() => handleSort("24hChange")}>
                        <img
                          src={ArrowDownIcon}
                          alt="Sort Descending"
                          className={`h-2 w-2 ${
                            sortDirection["24hChange"] === "desc"
                              ? "text-red-500"
                              : ""
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="table-container max-h-[500px] overflow-auto">
          <table className="w-full table-fixed">
            <tbody className="bg-slate-100 dark:bg-[#1A1D20]">
              {sortedAssets?.map((asset, index) => (
                <tr key={index}>
                  <td className="py-4 px-4 flex items-center text-sm">
                    <div className="flex items-center space-x-3 min-w-0 flex-grow">
                      <div className="flex-shrink-0 relative w-8 h-8">
                        <img
                          src={asset.logo_url}
                          alt={
                            asset.contract_name || asset.contract_ticker_symbol
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = CoinIcon;
                          }}
                          className="w-full h-full object-contain"
                        />
                        <img
                          src={
                            chains?.find(
                              (chain) =>
                                chain?.name?.toLowerCase() === asset?.chainName
                            )?.logo_url
                          }
                          alt="Chain logo"
                          className="absolute bottom-0 right-0 w-4 h-4 rounded-full border-2 border-[#6D7072] bg-white"
                        />
                      </div>
                      <div className="min-w-0 flex-grow">
                        <div className="flex items-center space-x-2">
                          {asset.verified === false && (
                            <img
                              src={SpamIcon}
                              alt="Spam Icon"
                              className="w-10 h-10 flex-shrink-0"
                            />
                          )}
                          <div className="text-sm font-medium truncate">
                            {asset.contract_ticker_symbol}
                          </div>
                        </div>
                        <div className="text-xs text-[#6D7072] truncate">
                          {asset.contract_name}
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0 ml-2">
                      <img
                        src={AIICON2}
                        alt="AI Icon"
                        className="cursor-pointer w-8 h-8 hover:scale-125 transition-all duration-500 "
                        onClick={() => handleAIClick(asset)}
                      />
                    </div>
                  </td>
                  <td className="py-4 px-4 w-1/6">
                    {formatAssetBalance(asset.balance)}{" "}
                    {asset.contract_ticker_symbol.length > 5
                      ? `${asset.contract_ticker_symbol.substring(0, 5)}...`
                      : asset.contract_ticker_symbol}
                  </td>
                  <td className="py-4 px-4 w-1/6">
                    {
                      currencies?.find(
                        (currency) =>
                          currency["Currency Code"].toLowerCase() ===
                          activeCurrency
                      )?.["Currency Symbol"]
                    }
                    {(asset.quote_rate * conversionRate).toFixed(2)}
                  </td>
                  <td className="py-4 px-4 w-1/6">
                    {
                      currencies?.find(
                        (currency) =>
                          currency["Currency Code"].toLowerCase() ===
                          activeCurrency
                      )?.["Currency Symbol"]
                    }
                    {(asset.quote * conversionRate).toFixed(2)}
                  </td>
                  <td className="py-4 px-4 w-1/6">
                    {((asset.quote / totalPortfolioValue) * 100).toFixed(2)}%
                  </td>
                  <td className="py-4 px-4 w-1/6">
                    {asset.quote_pct_change_24h >= 0 ? (
                      <span className="text-green-500 flex items-center">
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          className="p-[0.5rem]"
                        />
                        {Math.abs(asset.quote_pct_change_24h).toFixed(2)}%
                      </span>
                    ) : (
                      <span className="text-red-500 flex items-center">
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          className="p-[0.5rem]"
                        />
                        {Math.abs(asset.quote_pct_change_24h).toFixed(2)}%
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
