import React, { useState, useCallback, useEffect, useRef } from "react";
import CloseIcon from "../assets/Icons/close.svg";
import useStore from "../store";
import { questions } from "../utils/chatQns";
import { fetchQueryResponse } from "../api/safleAI";
import AIICON2 from "../assets/Icons/AI-icon-2.svg";
import AI_ICON_LIGHT from "../assets/Icons/AI_ICON_LIGHT-1.svg";

import ReactMarkdown from "react-markdown";
import NFTnotFound from "../assets/Icons/NFT-Placeholder.svg";

export const SafleAI = () => {
  const {
    chatContext,
    activeAIToken,
    setIsAIActive,
    setChatContext,
    setActiveAIToken,
  } = useStore();
  const [chatHistory, setChatHistory] = useState([]);
  const [activeQuery, setActiveQuery] = useState("");
  const [streamingResponse, setStreamingResponse] = useState("");
  const [chatDisabled, setChatDisabled] = useState(false);
  const bottomRef = useRef(null);
  const theme = useStore((state) => state.theme);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [chatHistory, streamingResponse]);

  function filterPayload(payload) {
    const filteredPayload = [];
    let lastRole = null;
  
    for (let i = payload.length - 1; i >= 0; i--) {
      const current = payload[i];
  
      if (current.role !== lastRole) {
        filteredPayload.unshift(current);
        lastRole = current.role;
      } else if (current.role === 'assistant') {
        filteredPayload.unshift(current);
      }
    }
  
    return filteredPayload;
  }

  const getLast10Messages = () => {
    // Filter out the last 5 messages from chatHistory
    let recentMessages = chatHistory.slice(-10);
    if (chatContext === "token") {
      recentMessages = recentMessages.map((obj, i) =>
        i === recentMessages.length - 1
          ? {
              ...obj,
              content: obj.content.replace(
                /token/g,
                activeAIToken?.contract_name +
                  "(" +
                  activeAIToken?.contract_ticker_symbol +
                  ")"
              ),
            }
          : obj
      );
    }
    if (chatContext === "nft") {
      recentMessages = recentMessages.map((obj, i) =>
        i === recentMessages.length - 1
          ? {
              ...obj,
              content: obj.content.replace(
                /NFT/g,
                "NFT of" +
                  (activeAIToken?.description || activeAIToken?.token_id) +
                  "(" +
                  activeAIToken?.asset_contract +
                  "on" +
                  activeAIToken?.chainName +
                  "chain)"
              ),
            }
          : obj
      );
    }
    return recentMessages;
  };

  const fetchResponse = useCallback(async () => {
    if (chatHistory.slice(-1)[0]?.role === "user") {
      setChatDisabled(true);
      try {
        const response = await fetchQueryResponse(filterPayload(getLast10Messages()));

        const reader = response.getReader();
        const decoder = new TextDecoder();

        let responseText = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });

          // Process the JSON object
          const dataLines = chunk.split("\n");
          // eslint-disable-next-line no-loop-func
          dataLines.forEach((line) => {
            if (line.trim().startsWith("data:")) {
              const jsonStr = line.trim().slice(5);
              if (jsonStr) {
                try {
                  const jsonObj = JSON.parse(jsonStr);
                  const content = jsonObj.choices[0]?.delta?.content;
                  if (content) {
                    responseText += content;
                    setStreamingResponse(responseText); // Update streaming response
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              }
            }
          });
        }

        // After streaming is done, add the final response to the chat history
        setChatHistory((prev) => [
          ...prev,
          { role: "assistant", content: responseText },
        ]);
        setStreamingResponse("");
        setChatDisabled(false);
      } catch (error) {
        setStreamingResponse(error?.toString()?.slice(7));
        setChatDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory.slice(-1)[0]?.role === "user") {
      fetchResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistory]);

  const handleInputChange = (e) => {
    if (streamingResponse === "Sorry, something went wrong.") {
      setChatHistory((prevChatHistory) => {
        // Create a new array excluding the last item
        return prevChatHistory.slice(0, -1);
      });
      setStreamingResponse("");
    }
    setActiveQuery(e.target.value);
  };

  // Handle Enter key press
  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      if (activeQuery) {
        setChatHistory((prev) => [
          ...prev,
          { role: "user", content: activeQuery },
        ]);
        setActiveQuery("");
      }
    }
  };

  return (
    <div className="absolute flex flex-col right-4 bottom-4 z-[1000] text-black dark:text-white h-[85%] min-w-[350px] w-[25%] rounded-lg border-[#25292D] dark:bg-[#1A1D20] bg-white">
      <div className="p-4 dark:bg-[#25292D] flex flex-row justify-between items-center bg-[#EFF1F7]">
        <div className="flex flex-row gap-2">
          <div>
            {theme === "light" ? (
              <img src={AI_ICON_LIGHT} alt="AI Icon" />
            ) : (
              <img src={AIICON2} alt="AI Icon" />
            )}
          </div>
          <div className="pt-2 text-black dark:text-white">Hi! This is Safle AI</div>
        </div>
        <div className="flex flex-row-reverse items-center gap-2">
          <div
            onClick={() => {
              setIsAIActive(false);
              setChatContext("");
              setActiveAIToken(null);
            }}
            className="cursor-pointer"
          >
            <img
              src={CloseIcon}
              alt="Clear Icon"
              className="invert h-[17px] w-[17px]"
              style={{
                filter:
                  "invert(29%) sepia(20%) saturate(277%) hue-rotate(192deg)",
              }}
            />
          </div>
          {chatContext === "token" && (
            <div>
              <div className="flex flex-row items-center justify-start rounded-lg gap-2 px-4 py-1 w-[110px] dark:bg-[#1A1D20] bg-white">
                <div className="">
                  <img
                    src={activeAIToken?.logo_url}
                    alt="AI Icon"
                    className="w-[20px] h-[20px] rounded-[10px]"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col text-sm dark:text-white text-black">
                    {activeAIToken?.contract_ticker_symbol}
                  </div>
                  <div className="flex flex-col text-xs text-[#6D7072]">
                    {activeAIToken?.contract_name}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-4 flex-grow overflow-scroll no-scrollbar">
        {!chatHistory.length && (
          <div className="flex flex-col gap-2">
            {chatContext === "nft" && (
              <div className="text-xs">{activeAIToken?.description}</div>
            )}
            {chatContext === "nft" && (
              <div className="w-full h-40 rounded-lg overflow-hidden flex items-center justify-center">
                <img
                  src={activeAIToken?.image_url}
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                    e.target.src = NFTnotFound;
                  }}
                  alt="NFT"
                />
              </div>
            )}
            <div className="text-[14px] text-[#6D7072]">
              Hi, How can I help you today?
            </div>
            <div className="flex flex-col gap-2 my-2">
              {questions?.[chatContext]?.length &&
                questions?.[chatContext]?.map((qn) => (
                  <div
                    onClick={() => {
                      setChatHistory((prev) => [
                        ...prev,
                        { role: "user", content: qn },
                      ]);
                      setActiveQuery("");
                    }}
                    className="dark:bg-[#222529] dark:text-white p-2 text-[12px] rounded-[8px] border-[1px] cursor-pointer text-black bg-white border-[#E9EAEA]"
                  >
                    {qn}
                  </div>
                ))}
            </div>
          </div>
        )}
        {chatHistory.length !== 0 && (
          <div className="flex flex-col gap-2">
            {chatHistory?.map((chatItem) => (
              <div
                className={`flex ${
                  chatItem?.role === "assistant"
                    ? "flex-row"
                    : "flex-row-reverse"
                } items-start gap-2`}
              >
                {/* {chatItem?.role === "user" ? <div>You</div> : <div>AI</div>} */}
                <div
                  className={`py-2 px-4 rounded-lg ${
                    chatItem?.role === "assistant"
                      ? "dark:bg-slate-800 dark:text-white text-black bg-slate-200 mr-8"
                      : "bg-blue-700 text-white ml-8"
                  }`}
                >
                  <ReactMarkdown>{chatItem?.content}</ReactMarkdown>
                </div>
              </div>
            ))}
          </div>
        )}
        {streamingResponse && (
          <div className="flex flex-col my-2">
            <div className="flex flex-row items-start gap-2">
              {/* <div>AI</div> */}
              <div className="py-2 px-4 rounded-lg mr-8 border-[1px] text-black dark:text-white border-[#E9EAEA] bg-white dark:bg-black">
                <ReactMarkdown>{streamingResponse}</ReactMarkdown>
              </div>
            </div>
          </div>
        )}
        <div ref={bottomRef} />
      </div>
      <div className="px-4 pb-2 flex flex-row">
        <input
          type="text"
          value={activeQuery}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          disabled={chatDisabled}
          placeholder="Ask me anything!"
          className="px-4 py-2 dark:bg-[#101113] dark:text-white text-base rounded-[10px] border-[1px] dark:border-[#1A1D20] w-full text-black border-[#E9EAEA] bg-white"
        />
      </div>
    </div>
  );
};
