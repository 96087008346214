
import React from "react";
import useStore from "../store";
import safleLogo from "../assets/SafleLens.svg";
import SafleLogoLightMode from "../assets/Icons/safle-logo-lightMode.svg";
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import { HiGlobeAsiaAustralia } from "react-icons/hi2";
import { BiLogoTelegram } from "react-icons/bi";

const footerLinks = [
    {
        key: "Safleverse",
        value: [
            {
                key: "SafleID",
                link: ""
            },
            {
                key: "Wallet Utility",
                link: ""
            },
            {
                key: "Safle Vault",
                link: ""
            },
        ],
    },
    {
        key: "$SAFLE Token",
        value: [
            {
                key: "Tokenomics",
                link: ""
            },
            {
                key: "Roadmap",
                link: ""
            },
        ]
    },
    {
        key: "Developers",
        value: [
            {
                key: "Developer Suite",
                link: ""
            },
            {
                key: "Safle Keyless",
                link: ""
            },
        ]
    },
    {
        key: "About",
        value: [
            {
                key: "Mission",
                link: ""
            },
            {
                key: "Crew",
                link: ""
            },
            {
                key: "Careers",
                link: ""
            },
            {
                key: "Whitepaper",
                link: ""
            },
            {
                key: "Contacts Us",
                link: ""
            },
        ]
    },
    {
        key: "Policies",
        value: [
            {
                key: "Privacy Policy",
                link: ""
            },
            {
                key: "Terms & Conditions",
                link: ""
            },
        ],
    }
]

export const Footer = () => {
    const { theme, setTheme } = useStore();
    const [email, setEmail] = React.useState("");

const handleClick = () => {
    // alert(`Subscribed with email: ${email}`);
    setEmail("")
    };

return (
    <div className="w-full flex-col px-32 divide-y divide-[#222529]">
        <div className="p-2">
            {theme === "light" ? (
                        <img
                            src={SafleLogoLightMode}
                            alt="Safle Logo"
                            className="w-[9rem] h-[3rem] mr-2"
                        />
                        ) : (
                        <img
                            src={safleLogo}
                            alt="Safle Logo"
                            className="w-[9rem] h-[3rem] mr-2"
                        />
            )}
        </div>
        <div className="flex flex-row p-2">
            <div className="w-[60%] flex flex-row flex-wrap">
                {footerLinks?.map(item => <div className="w-[150px] flex-col">
                    <div className="text-black dark:text-white p-2">{item?.key}</div>
                    {item?.value?.map(subitem => <div className="text-[#6D7072] p-2">{subitem?.key}</div>)}
                </div>)}
            </div>
            <div>
                <div className="px-2 pb-4">
                    <div className="text-black dark:text-white p-2 text-left">Be a part of Safle Community</div>
                    <div className="flex flex-row p-2 w-full text-[#6D7072] gap-4">
                        <div
                            className="cursor-pointer"
                            onClick={() =>
                                window.open(
                                "https://discord.com/invite/getsafle",
                                "_blank",
                                "noopener,noreferrer"
                            )
                            }
                        >
                            <FaDiscord size={24} />
                        </div>
                        <div
                        className="cursor-pointer"
                        onClick={() =>
                            window.open(
                            "https://x.com/GetSafle",
                            "_blank",
                            "noopener,noreferrer"
                            )
                        }
                        >
                            <FaXTwitter size={24} />
                        </div>
                        <div
                        className="cursor-pointer"
                        onClick={() =>
                            window.open(
                            "https://t.me/saflechat",
                            "_blank",
                            "noopener,noreferrer"
                            )
                        }
                        >
                            <BiLogoTelegram size={24} />
                        </div>
                        <div
                        className="cursor-pointer"
                        onClick={() =>
                            window.open(
                            "https://www.instagram.com/getsafle/",
                            "_blank",
                            "noopener,noreferrer"
                            )
                        }
                        >
                            <FaInstagram size={24} />
                        </div>
                        <div
                        className="cursor-pointer"
                        onClick={() =>
                            window.open(
                            "https://safle.medium.com/",
                            "_blank",
                            "noopener,noreferrer"
                            )
                        }
                        >
                            <FaMedium size={24} />
                        </div>
                        <div
                        className="cursor-pointer"
                        onClick={() =>
                            window.open(
                            "https://safle.com/",
                            "_blank",
                            "noopener,noreferrer"
                            )
                        }
                        >
                            <HiGlobeAsiaAustralia size={24} />
                        </div>
                    </div>
                </div>
                <div className="my-4 mx-2">
                    <div className="text-black dark:text-white p-2 text-left">Subscribe to our Newsletter</div>
                    <div className="text-[#687189] text-left p-2">Stay updated with the latest news & new feature updates.</div>
                    <div className="relative mx-2 my-4 flex flex-row items-center">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className=" w-full flex-grow p-3 bg-transparent text-black dark:text-white border-[1px] border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleClick}
                            className="absolute right-1 bg-blue-500 text-white px-5 py-2 rounded-md hover:bg-blue-600 transition-colors"
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <div className="text-[#6D7072] text-left p-2">
        Copyright © 2024 Safle Lens. All rights reserved
        </div>
    </div>
)
}
          


