import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../store";
import { checkUser, isPublicAddress, isENS} from "../utils/helper";
import SearchIcon from "../assets/Icons/Search.svg";
import { DownloadAppButton } from "./DownloadAppPopUp";
import "react-toastify/dist/ReactToastify.css";
import RightArrow from "../assets/Icons/Arrow.svg";
import ThemeIcon from "../assets/Icons/theme.svg";

export const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { setUser, sideBar, setSideBar, theme, setTheme } = useStore();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // Determine the title based on the current route
  const getTitle = (pathname) => {
    switch (pathname) {
      case "/":
        return "Home";
      case "/featureRequest":
        return "Request a Feature";
      default:
        if (pathname.includes("/tokens")) return "Tokens";
        if (pathname.includes("/nfts")) return "NFTs";
        if (pathname.includes("/transactions")) return "Transactions";
        return "Dashboard";
    }
  };
  

  const handleInputChange = async (e) => {
    setSearchQuery(e.target.value);
    setLoading(true);
    setSuccess(false);
    setError(false);
    const walletId = e.target.value ;
    try {
      const response = await checkUser({ walletId });
      console.log(response, "response")
      setLoading(false);
      setError(false);
      if (e.target.value === "") {
        setButtonDisabled(true);
        return;
      }
      if (response.ENS) {
        setSuccess("ENS Detected");
        setButtonDisabled(false);
      } else if (response.address && walletId.includes("0x")) {
        setSuccess("Public Address Detected");
        setButtonDisabled(false);
      }else if (response.safleId) {
        setSuccess("SafleID Detected");
        setButtonDisabled(false);
      } else {
        if (!isPublicAddress(walletId) && walletId.includes("0x")) {
          setError("Public Address not found");
        } else if (isENS(walletId)) {
          setError("ENS not found");
        } else {
          setError("SafleID not found");
        }
        setButtonDisabled(true);
       
      }
    } 
    catch (error) {
      setError(false);
      setLoading(false);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && searchQuery) {
      setLoading(true);
      const user = await checkUser({ walletId: searchQuery });
      if (typeof user !== "string") {
        setUser(user);
        navigate(`/${searchQuery}`);
      } else {
        setError(user);
        setLoading(false);
      }
    }
  };

  const handleIconClick = async () => {
    if (searchQuery) {
      setLoading(true);
      const user = await checkUser({ walletId: searchQuery });
      if (typeof user !== "string") {
        setUser(user);
        navigate(`/${searchQuery}`);
      } else {
        setError(user);
        setLoading(false);
      }
    }
  };

  const toggleSidebar = () => {
    setSideBar(!sideBar);
  };

  return (
    <div className="flex justify-between items-center p-4 dark:bg-[#101113] dark:text-white text-black bg-white border-[1px] dark:border-[#1A1D20]">
      <div className="flex items-center">
        <button
          className="block md:hidden text-2xl mr-4"
          onClick={toggleSidebar}
        >
          &#9776; {/* Hamburger menu icon */}
        </button>
        <h1 className="text-lg">{getTitle(location.pathname)}</h1>
      </div>
      <div className="relative md:w-[340px]">
      <div className="flex items-center justify-center mt-4">
              <div className="relative md:w-[450px]">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  className="absolute left-5 top-1/2 transform -translate-y-1/2 w-5 h-5 dark:invert hidden md:block"
                />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => handleInputChange(e)}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter SafleID, Address or ENS"
                  className={`pl-10 pr-4 px-4 py-2 dark:bg-[#101113] text-base rounded-full border-[1px] md:w-[450px] outline-none
                    ${
                      error
                        ? "border-red-500"
                        : success
                        ? "border-green-500"
                        : "border-[#1A1D20]"
                    }`}
                    style={{
                      paddingLeft: '3rem', // You can adjust the value as needed
                      paddingRight: '3rem', // Adjust padding-right as needed
                    }}
                />
                {buttonDisabled ? (
                  <img
                    src={RightArrow}
                    alt="Right Arrow"
                    className={`absolute top-1/2 transform -translate-y-1/2 opacity-50 cursor-not-allowed rounded-full bg-blue-500 p-1 right-1 fade-in`}
                    onClick={handleIconClick}
                  />
                ) : (
                  <img
                    src={RightArrow}
                    alt="Right Arrow"
                    className={`absolute top-1/2 transform -translate-y-1/2 cursor-pointer rounded-full bg-blue-500 p-1 right-1 `}
                    onClick={handleIconClick}
                  />
                )}
              </div>
            </div>
        <div className="items-center justify-center h-[1rem] pt-1 pl-[6rem]">
          {error && <div className="text-red-500">{error}</div>}
          {success && <div className="text-green-500">{success}</div>}
        </div>
      </div>

      <style>
        {`
            .Toastify {
                position: absolute;
            }
            .Toastify__toast {
                
                top: -15px !important
            }
            `}
      </style>
      {/* <ToastContainer /> */}
      
      <div className="flex flex-row items-center">
      <DownloadAppButton />
      <div className="pl-4">
      <img
          onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
          src={ThemeIcon}
          alt="Theme Icon"
          className="w-10 h-10 cursor-pointer" // Adjust size as needed
        />
        </div>
      </div>
    </div>
  );
};
