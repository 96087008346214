import React, { useEffect, useState } from "react";
import NFTnotFound from "../assets/Icons/NFT-Placeholder.svg";
import { NFTPrice } from "../api/userApi";
import { currencies } from "../utils/currencies";
import useStore from "../store";
import AIICON2 from "../assets/Icons/new-ai-icon.svg";


export const NFTCard = ({ nft }) => {
  const [price, setPrice] = useState({ price: "0", usdPrice: 0 });
  const {
    activeCurrency,
    conversionRate,
    chains,
    setIsAIActive,
    setChatContext,
    setActiveAIToken,
  } = useStore();
  const truncatedTokenId =
    nft?.token_id.length > 5
      ? nft?.token_id.slice(0, 5) + "..."
      : nft?.token_id;

  useEffect(() => {
    async function fetchPrice() {
      const response = await NFTPrice(
        nft?.chainName,
        nft?.asset_contract,
        nft?.token_id
      );
      if (response && response?.price_formatted) {
        setPrice({
          price:
            response?.price_formatted +
            " " +
            response?.payment_token?.token_symbol,
          usdPrice: response?.usd_price_at_sale,
        });
      }
    }
    fetchPrice();
  }, [nft]);

  const handleAIClick = () => {
    setIsAIActive(true);
    setChatContext("nft");
    setActiveAIToken(nft);
  };

  return (
    // <div>{nft?.asset_contract_name}</div>
    <div className="md:flex-row md:items-center dark:bg-[#1A1D20] bg-slate-100 text-black dark:text-white shadow-md rounded-lg overflow-hidden p-4 md:p-6 mb-4">
      <img
        src={nft?.image_url || NFTnotFound}
        alt={nft?.asset_contract_name}
        className="w-full md:w-96 object-cover h-48 md:h-36 rounded-lg"
        onError={(e) => {
          e.target.onerror = null; // Prevents infinite loop if fallback image fails too
          e.target.src = NFTnotFound;
        }}
      />
      <div className="p-2 flex flex-col justify-between h-[calc(100%-135px)]">
        <div>
        <h2 className="text-base">{nft?.asset_contract_name}</h2>
        <div className="flex justify-between text-[13px] text-[#6D7072] pt-2 pb-2 ">
          <div className="relative inline-block group">
            <span className="border-[1px] border-[#25292D] rounded-md p-1 group">
              Token ID: #{truncatedTokenId}
              {nft?.token_id.length > 5 && (
                <span className="absolute w-auto p-2 min-w-max left-1/2 transform -translate-x-1/2 -translate-y-full bg-black text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-1">
                  {nft?.token_id}
                </span>
              )}
            </span>
          </div>
          <div className="">
            <img
              src={
                chains?.find(
                  (chain) => chain?.name?.toLowerCase() === nft?.chainName
                )?.logo_url
              }
              alt="Chain logo"
              className="rounded-full w-[15px] h-[15px] relative border-[#6D7072]"
            />
          </div>
        </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <p className="text-[#6D7072]">last sale price</p>
            <div className="flex items-center gap-2">
              <h2 className="text-base">{price?.price ? price?.price : 0}</h2>
              <h2 className="text-base">
                {price?.data?.nftPrice?.floor_price
                  ? `${price.data.nftPrice.floor_price} ETH`
                  : "0 ETH"}
              </h2>
              <p className="text-[#6D7072]">
                {
                  currencies?.find(
                    (currency) =>
                      currency["Currency Code"].toLowerCase() === activeCurrency
                  )?.["Currency Symbol"]
                }
                {price?.usdPrice ? price?.usdPrice * conversionRate : 0}
              </p>
            </div>
          </div>
          {price?.data?.nftPrice?.floor_price > 0 && (
            <div>
              <p className="text-[#6D7072]">last sale price</p>
              <div className="flex items-center gap-2">
                <h2 className="text-base">
                  {`${price.data.nftPrice.floor_price} ETH`}
                </h2>
                <p className="text-[#6D7072]">
                  {
                    currencies?.find(
                      (currency) =>
                        currency["Currency Code"].toLowerCase() ===
                        activeCurrency
                    )?.["Currency Symbol"]
                  }
                  {/* {price?.usdPrice ? price?.usdPrice * conversionRate : 0} */}
                  {price?.data?.nftPrice?.floor_price
                    ? `$${(
                        price.data.nftPrice.floor_price * conversionRate
                      ).toFixed(2)} USD`
                    : "$0 USD"}
                </p>
              </div>
            </div>
          )}
          <div>
            <div>
              <img
                src={AIICON2}
                alt="AI Icon"
                className="cursor-pointer hover:scale-125 transition-all duration-500"
                onClick={() => handleAIClick(nft)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
