import React, { useState, useEffect } from "react";
import useStore from "../store";
import RefreshIcon from "../assets/Icons/refresh.svg";
import ExpandIcon from "../assets/Icons/Expand.svg";
import { useLocation } from "react-router-dom";

export const RefreshGroup = ({ showChainWorth, handleExpandClick }) => {
  const location = useLocation();
  const [isAnimating, setIsAnimating] = useState(false);
  const {
    // user,
    // chainWorth,
    // conversionRate,
    // activeCurrency,
    tokenRefreshTime,
    nftRefreshTime,
    txnRefreshTime,
    setRefresh,
  } = useStore();

  const refreshTime = location?.pathname?.includes("nfts")
    ? nftRefreshTime
    : location?.pathname?.includes("transactions")
    ? txnRefreshTime
    : tokenRefreshTime;

  const [timePassed, setTimePassed] = useState("");

  useEffect(() => {
    const calculateTimePassed = () => {
      const now = Date.now();
      const diff = now - refreshTime;
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(diff / (1000 * 60 * 60));

      if (minutes < 1) {
        setTimePassed("Just now");
      } else if (minutes < 60) {
        setTimePassed(`${minutes} min`);
      } else {
        const remainingMinutes = minutes % 60;
        setTimePassed(
          `${hours} hr${hours > 1 ? "s" : ""} ${remainingMinutes} min`
        );
      }
    };

    calculateTimePassed();
    const interval = setInterval(calculateTimePassed, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [refreshTime]);
  //   const handleExpandClick = () => {
  //     setIsExpanded(!isExpanded);
  //   };
  const handleRefreshClick = () => {
    setIsAnimating(true);
    setRefresh((refresh) => refresh + 1);

    // Reset animation after it finishes
    setTimeout(() => setIsAnimating(false), 500); // 500ms matches the duration of animate-spin
  };

  return (
    <div className="flex flex-row items-center">
      {refreshTime ? (
        <div className="flex flex-row">
          <div className="mr-4 text-[#6D7072] text-sm">Updated</div>
          <div className="mr-4 text-sm">{timePassed}</div>
          {timePassed !== "Just now" && (
            <div className="mr-4 text-sm text-[#6D7072]">ago</div>
          )}
          {/* <div className="mr-4 text-sm text-[#6D7072]">ago</div> */}
        </div>
      ) : null}
      <div
        className={`mr-4 cursor-pointer invert dark:invert-0 ${isAnimating ? "animate-spin" : ""}`}
        onClick={handleRefreshClick}
      >
        <img src={RefreshIcon} alt="icon" className={`mr-2`} />
      </div>
      {showChainWorth && (
        <div
          onClick={handleExpandClick}
          className="flex mr-2 border-[1px] p-2 text-base rounded-[10px] border-[#1A1D20] items-center cursor-pointer"
        >
          <div>All Chains</div>
          <div>
            <img src={ExpandIcon} alt="icon" className="invert-0 dark:invert ml-2" />
          </div>
        </div>
      )}
    </div>
  );
};
