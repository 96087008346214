// src/components/DashboardLayout.js

import React, { useEffect, useState } from "react";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { checkUser } from "../utils/helper";
import AIICON from "../assets/Icons/generic-ai-icon.svg";
import SpinnerLogo from "./SpinnerLogo";

import {
  supportedChains,
  assetsOnChains,
  fetchConversionRate,
} from "../api/userApi";
import useStore from "../store";
import { SafleAI } from "./SafleAI";

export const DashboardLayout = ({ children }) => {
  const { walletId } = useParams();
  const {
    user,
    chains,
    refresh,
    setTokenRefreshTime,
    activeCurrency,
    isAIActive,
    setUser,
    setChains,
    setChainWorth,
    setActiveChains,
    setConversionRate,
    setIsAIActive,
    setChatContext,
    setActiveAIToken,
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function checkingUser() {
      if (walletId) {
        setLoading(true);
        const user = await checkUser({ walletId: walletId });
        if (typeof user !== "string") {
          const supportedChainList = await supportedChains();
          setUser(user);
          setChains(supportedChainList);
          setActiveChains(
            supportedChainList?.map((chain) => chain?.name?.toLowerCase())
          );
          // navigate(`/${searchQuery}`);
        } else {
          navigate(`/${walletId}/notFound`);
        }
      }
    }

    checkingUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId, refresh]);

  useEffect(() => {
    async function gettingAssets() {
      if (chains && chains?.length > 0) {
        const assetList = await Promise.all(
          chains.map(async (chain) => {
            return await assetsOnChains(
              chain?.name?.toLowerCase(),
              user.address
            );
          })
        );

        setChainWorth(assetList);
        setTimeout(() => {
        }, 3000);
        const timeStamp = Date.now();
        setTokenRefreshTime(timeStamp);

        setLoading(false);
      }
    }

    gettingAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains]);

  useEffect(() => {
    async function fetchingRates() {
      if (activeCurrency === "USD") {
        setConversionRate(1);
      } else {
        const getRate = await fetchConversionRate(
          activeCurrency?.toUpperCase()
        );
        setConversionRate(getRate);
      }
    }

    fetchingRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCurrency]);

  const handleAIClick = () => {
    setIsAIActive(true);
    if (location.pathname.includes("tokens")) {
      setChatContext("tokenPage");
    } else if (location.pathname.includes("nfts")) {
      setChatContext("nftPage");
    } else if (location.pathname.includes("transactions")) {
      setChatContext("txnPage");
    } else {
      setChatContext("general");
    }
    setActiveAIToken(null);
  };

  if(loading) {
    return <div className="flex bg-white dark:bg-[#101113] justify-center items-center">
      <SpinnerLogo />
    </div>
  }

  return (
    <div className="flex bg-white dark:bg-[#101113]">
      <Sidebar walletId={walletId} />
      <div className="h-screen w-screen md:w-[100%] flex flex-col relative">
        {location.pathname && location.pathname !== "/" && <Navbar />}
        <div className="p-4 flex grow overflow-scroll no-scrollbar">
          {children}
        </div>
      </div>
      <img
        src={AIICON}
        alt="AI Icon"
        className="absolute bottom-10 right-12 cursor-pointer hover:scale-125 transition-all duration-500"
        style={{ zIndex: 100 }}
        onClick={() => handleAIClick()}
      />
      {isAIActive && <SafleAI />}
    </div>
  );
};
