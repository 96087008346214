import React, { useState } from "react";
import FaviconIcon from "../assets/Icons/Favicon.svg";
import AppPopUp from "../assets/Icons/AppDownloadPopup.svg";

export const DownloadAppButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    console.log("Opening modal");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log("Closing modal");
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        className="flex items-center bg-blue-500 text-white text-sm py-2 px-4 hover:bg-blue-700 rounded-full"
        onClick={handleButtonClick}
      >
        <img src={FaviconIcon} alt="Logo" className="w-6 h-6 mr-2" />
        Download Safle Wallet
      </button>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-[#141517CC] backdrop-blur-sm flex items-center justify-center"
          style={{ zIndex: 1000 }}
        >
          <div className="p-4 rounded shadow-lg">
            <img
              src={AppPopUp}
              alt="App Download Popup"
              className="w-full h-auto"
              onClick={closeModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};
