// src/App.js

import React, { useState, useEffect } from "react";
import { ComingSoonMobileVersion } from "./components/ComingSoonMobileVersion";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { DashboardLayout } from "./components";
import {
  HomeScreen,
  TokensScreen,
  NFTsScreen,
  TxnScreen,
  WalletNotFound,
} from "./pages";
import "./App.css";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust the width threshold as needed
    };

    handleResize(); // Check the initial size
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobile) {
    return <ComingSoonMobileVersion />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/:walletId">
          <Route index element={<Navigate to="tokens" replace />} />
          <Route
            path="tokens"
            element={
              <DashboardLayout>
                <TokensScreen />
              </DashboardLayout>
            }
          />
          <Route
            path="nfts"
            element={
              <DashboardLayout>
                <NFTsScreen />
              </DashboardLayout>
            }
          />
          <Route
            path="transactions"
            element={
              <DashboardLayout>
                <TxnScreen />
              </DashboardLayout>
            }
          />
          <Route
            path="notFound"
            element={
              <DashboardLayout>
                <WalletNotFound />
              </DashboardLayout>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
