import React, { useState, useEffect } from "react";
import { currencies } from "../utils/currencies";
import useStore from "../store";

export const ChainCardGroup = ({ cards, isExpanded }) => {
  const [cardsPerRow, setCardsPerRow] = useState(5);
  const { chains, activeCurrency, conversionRate } = useStore();

  const totalPortfolioValue = cards?.reduce(
    (total, chain) => total + chain.net_asset_worth,
    0
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setCardsPerRow(2);
      } else if (window.innerWidth < 768) {
        setCardsPerRow(2);
      } else if (window.innerWidth < 1024) {
        setCardsPerRow(3);
      } else if (window.innerWidth < 1280) {
        setCardsPerRow(4);
      } else {
        setCardsPerRow(5);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {/* <div className="flex flex-wrap gap-4 my-2">
        {cards
          .slice(0, isExpanded ? cards.length : cardsPerRow)
          .map((item, index) => (
            <div
            key={index}
            className={`min-w-[calc(50%-16px)] sm:min-w-[calc(50%-16px)] md:min-w-[calc(33.33%-16px)] lg:min-w-[calc(25%-16px)] xl:min-w-[calc(20%-16px)] bg-slate-200 dark:bg-[#141719] p-4 rounded-lg flex flex-row items-start justify-between ${
              index === cards.length - 1 ? "gap-0" : "gap-4"
            }`}
          >
              <div className="flex flex-row items-start gap-2">
                <div>
                  <img
                    src={
                      chains?.find(
                        (chain) => chain?.name?.toLowerCase() === item?.chain
                      )?.logo_url
                    }
                    alt="Chain logo"
                    className="h-[24px] w-[24px]"
                  />
                </div>
                <div className="flex flex-col justify-start">
                  <div className="text-[#6D7072] text-[14px]">
                    {
                      chains?.find(
                        (chain) => chain?.name?.toLowerCase() === item?.chain
                      )?.name
                    }
                  </div>
                  <div className="text-[16px]">
                    {
                      currencies?.find(
                        (currency) =>
                          currency["Currency Code"].toLowerCase() ===
                          activeCurrency
                      )?.["Currency Symbol"]
                    }
                    {item?.net_asset_worth % 1 === 0
                      ? (item?.net_asset_worth * conversionRate)?.toString()
                      : (item?.net_asset_worth * conversionRate)?.toFixed(
                          Math.min(
                            2,
                            item?.net_asset_worth?.toString().split(".")[1]
                              .length
                          )
                        )}
                  </div>
                </div>
              </div>
              <div className="text-black dark:text-[#FFFFFF] text-[16px] mt-[1px]">
                {((item.net_asset_worth / totalPortfolioValue) * 100).toFixed(
                  2
                )}
                %
              </div>
            </div>
          ))}
      </div> */}

      <div className="flex flex-wrap gap-4 my-2">
        {cards
          .slice(0, isExpanded ? cards.length : cardsPerRow)
          .sort((a, b) => b.net_asset_worth - a.net_asset_worth) // Sort cards by net_asset_worth in descending order
          .map((item, index) => (
            <div
              key={index}
              className={`min-w-[calc(50%-16px)] sm:min-w-[calc(50%-16px)] md:min-w-[calc(33.33%-16px)] lg:min-w-[calc(25%-16px)] xl:min-w-[calc(20%-16px)] bg-slate-200 dark:bg-[#141719] p-4 rounded-lg flex flex-row items-start justify-between ${
                index === cards.length - 1 ? "gap-0" : "gap-4"
              }`}
            >
              <div className="flex flex-row items-start gap-2">
                <div>
                  <img
                    src={
                      chains?.find(
                        (chain) => chain?.name?.toLowerCase() === item?.chain
                      )?.logo_url
                    }
                    alt="Chain logo"
                    className="h-[24px] w-[24px]"
                  />
                </div>
                <div className="flex flex-col justify-start">
                  <div className="text-[#6D7072] text-[14px]">
                    {
                      chains?.find(
                        (chain) => chain?.name?.toLowerCase() === item?.chain
                      )?.name
                    }
                  </div>
                  <div className="text-[16px]">
                    {
                      currencies?.find(
                        (currency) =>
                          currency["Currency Code"].toLowerCase() ===
                          activeCurrency
                      )?.["Currency Symbol"]
                    }
                    {item?.net_asset_worth % 1 === 0
                      ? (item?.net_asset_worth * conversionRate)?.toString()
                      : (item?.net_asset_worth * conversionRate)?.toFixed(
                          Math.min(
                            2,
                            item?.net_asset_worth?.toString().split(".")[1]
                              ?.length
                          )
                        )}
                  </div>
                </div>
              </div>
              <div className="text-black dark:text-[#FFFFFF] text-[16px] mt-[1px]">
                {((item.net_asset_worth / totalPortfolioValue) * 100).toFixed(
                  2
                )}
                %
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
